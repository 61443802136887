.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.download-button {
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary-color);

  font-weight: 500;
  color: var(--white-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  cursor: pointer;

  &:hover {
    background-color: #2430af;
  }
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
